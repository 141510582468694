import * as React from "react";

import {
    useAddComissionSettingMutation,
    useGetComissionSettingQuery
} from "api/settingsApi";
import { Status } from "features/common/Status/Status";
import { useParams } from "react-router-dom";

import {
    Button,
    Stack,
    TextField,
    Typography
} from "@mui/material";

export const Comission = () => {
    const { id } = useParams();
    
    const { data: comission, isSuccess } = useGetComissionSettingQuery(id);
    const [value, setValue] = React.useState<string |number>();

    React.useEffect(() => {
        if (!!isSuccess) {
            setValue(comission.value);
        }
    }, [comission]);

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
        }, [setValue]
    );

    const [addComission, status] = useAddComissionSettingMutation();

    const handleClick = React.useCallback(() => {
        addComission({ flat_id: Number(id), value: Number(value) });
    }, [value, addComission]);

    return (
        <Stack spacing={4} sx={{ width: "550px" }}>
            <Stack spacing={2}>
                <Typography variant="h5">
                    Комиссия
                </Typography>
                <Typography variant="subtitle1">
                    Необходимо указать комиссию.
                </Typography>
            </Stack>
            <Stack spacing={2}>
                <TextField
                    label="Комиссия, %"
                    value={value ?? ""}
                    onChange={handleChange}
                />
                <Button variant="contained" onClick={handleClick}>
                    Сохранить
                </Button>
            </Stack>
            <Status
                initialState={status.isSuccess || status.isError}
                isError={status.isError}
            />
        </Stack>
    );
};

