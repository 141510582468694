import * as React from "react";

import { IconButton } from "common/components";

export interface IProps {
    onAddService: () => void;
}

export const ServicesActions = ({ onAddService }: IProps) => {
    return (
        <IconButton 
			label="Добавить"
			type="service"
			onClick={onAddService}
		/>
    );
};
