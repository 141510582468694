import * as React from "react";

import {
    useChangeAvatarMutation,
    useGetForFlatQuery
} from "api/flatAvatarApi";
import { IconButton } from "common/components";
import { Status } from "features/common/Status/Status";
import { useParams } from "react-router-dom";

import {
    Stack,
    Typography
} from "@mui/material";

export const FlatAvatar = () => {
    const { id } = useParams();
    const { data: flatAvatar } = useGetForFlatQuery(id);

    const [change, status] = useChangeAvatarMutation();

    const handleChangeAvatar = React.useCallback(() => {
        change({ flat_id: Number(id) });
    }, [id, change]);

    if (!flatAvatar) {
        return null;
    }

    const src = "data:image/png;base64, " + flatAvatar.data;
    return (
        <Stack spacing={4} sx={{ width: "550px" }}>
            <Stack spacing={2}>
                <Typography variant="h5">
                    Аватар квартиры
                </Typography>
                <Stack alignItems="start">
                    <IconButton
                        path={src}
                        width={85}
                        height={85}
                        label="Сменить"
                        onClick={handleChangeAvatar}
                    />
                </Stack>
            </Stack>
            <Status
                initialState={status.isSuccess || status.isError}
                isError={status.isError}
            />
        </Stack>
    );
};
