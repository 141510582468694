import * as React from "react";

import { Card } from "features/common/Card/Card";
import {
    FormProvider,
    SubmitHandler,
    useFieldArray,
    useForm
} from "react-hook-form";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
    Button,
    Grid,
    Typography
} from "@mui/material";

import {
    useAddOrUpdateServicesMutation,
    useGetServicesQuery,
    useLazyDeleteServiceQuery
} from "../../../api/servicesApi";
import { useGetServicesTypesQuery } from "../../../api/servicesTypesApi";
import { ISelectItem } from "../../common/models/ISelectItem";
import { getChangedServices } from "../helpers";
import { IService } from "../models/IService";
import { IServicesForm } from "../models/IServicesForm";
import { ServicesCardValidationSchema } from "../ServicesCardValidationSchema";
import { Service } from "./Service";
import { ServicesActions } from "./ServiceActions";

export const ServicesCard = () => {
	const { id } = useParams();
	const { data: existedServices, refetch } = 	useGetServicesQuery(id);

	const formMethods = useForm<IServicesForm>({
		defaultValues: {
			formData: [],
		},
		resolver: yupResolver(ServicesCardValidationSchema),
		mode: "onBlur",
	});

	const { control, handleSubmit, reset } = formMethods;

	const { fields, append, remove } = useFieldArray({
		control,
		name: "formData",
		keyName: "guid",
	});

	const DEFAULT_SERVICE = React.useMemo(() => {
		return {
			id: 0,
			service_type_id: -1,
			price: 0,
			flat_id: Number(id),
		} as IService;
	}, [id]);

	React.useEffect(() => {
		if (!!existedServices){
			reset({ formData : existedServices });
		}
	}, [existedServices, reset]);

	const addService = React.useCallback(() => {
		append(DEFAULT_SERVICE);
	}, [append, DEFAULT_SERVICE]);

	const { data: servicesTypes } = useGetServicesTypesQuery();

	const [deleteService] = useLazyDeleteServiceQuery();

    const removeService = React.useCallback(async (index?: number) => {
		if(index !== undefined) {
			remove(index);

			if(!!fields[index].id) {
				await deleteService(fields[index].id);
				refetch();
			}
		}
	 }, [fields, remove, deleteService, refetch]);

	const selectItems: ISelectItem[] = React.useMemo(
		() =>
			servicesTypes?.map((item) => ({
				name: item.name,
				value: item.id,
				id: item.id,
			})) ?? [],
		[servicesTypes]
	);

	const [addOrUpdateServices] = useAddOrUpdateServicesMutation();

	const handleSave: SubmitHandler<IServicesForm> = React.useCallback(
		async (data: IServicesForm) => {
			const changedServices = getChangedServices(data?.formData, existedServices);
			if (!!changedServices && changedServices.length > 0) {
				await addOrUpdateServices(data.formData);
				refetch();
			}
		},
		[existedServices, addOrUpdateServices, refetch]
	);

	if (!servicesTypes){
		return null;
	}

	return (
		<Card>
			<FormProvider {...formMethods}>
				<Typography
					sx={{
						fontSize: 18,
						paddingBottom: 4,
					}}
					color="text.primary"
					gutterBottom
				>
					Услуги
				</Typography>
				{fields.length === 0 ? (
					<>Вы не добавили еще ни одной услуги</>
				) : (
					<Grid container spacing={4}>
						<Grid item xs={12} sm={12}>
							{fields.map((field, index) => {
								return (
									<Service
										key={field.guid}
										index={index}
										options={selectItems}
										onRemove={removeService}
									/>
								);
							})}
						</Grid>
						<Grid item xs={12} sm={12}>
							<Button
								variant="contained"
								type="submit"
								onClick={handleSubmit(handleSave)}
								fullWidth
							>
								Сохранить
							</Button>
						</Grid>
					</Grid>
				)}
			</FormProvider>
			<ServicesActions onAddService={addService} />
		</Card>
	);
};
