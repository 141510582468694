import { IInvoiceData } from "features/invoice/models/IInvoiceData";

import { api } from "../app/api";
import { routes } from "../app/invoice";

const invoiceApi = api.injectEndpoints({
    endpoints: build =>({
        getDataForInvoice: build.query<IInvoiceData, any>({
            query: (id) => ({
				method: "GET",
				url: `${routes.getDataForInvoice}/${id}`,
			})
        })
    })
})

export const {
    useGetDataForInvoiceQuery
} = invoiceApi;