import * as React from "react";

import {
    Paper,
    Stack
} from "@mui/material";

import { LeftSideBar } from "./LeftSideBar";

export interface IProps {
    children: React.ReactNode[];
}

export const Card = ({ children }: IProps) => {
    return (
        <Stack direction="row">
			<LeftSideBar/>
            <Stack sx={{ width: 450 }}>
                <Paper
                    variant="outlined"
                    sx={{
                        px: 4.5,
                        pt: 5,
                        pb: 6,
                        borderRadius: 1,
                    }}
                >
                    {children[0]}
                </Paper>
            </Stack>
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ width: 250 }}
            >
                {children[1]}
            </Stack>
        </Stack>
    );
};
