import * as React from "react";

import {
	Grid,
	IconButton,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import { useFormContext } from "react-hook-form";
import { IServicesForm } from "../models/IServicesForm";
import { SelectInput, NumericInput } from "../../common/elements";

export interface IProps {
	index: number;
	options: any;
	onRemove: (id: number, index?: number) => void;
}

export const Service = ({ index, options, onRemove } : IProps) => {
	const { formState: { errors } } = useFormContext<IServicesForm>();
	
	return (
		<Grid
			container
			spacing={4}
			sx={{ pb: 3, mb: 5, borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
		>
			<Grid item xs={12} sm={8}>
				<SelectInput<IServicesForm>
					label="Услуга"
					name={`formData.${index}.service_type_id`}
					items={options}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={4} sx={{ textAlign: "end" }}>
				<IconButton
					color="error"
					onClick={() => onRemove(index)}
				>
					<DeleteIcon />
				</IconButton>
			</Grid>
			<Grid item xs={12} sm={4}>
				<NumericInput
					label="Стоимость"
					fieldName={`formData.${index}.price`}
					required={true}
					regex={/[^0-9[.]/g}
					length={8}
					error={!!errors.formData?.[index]?.price}
				/>
			</Grid>
		</Grid>
	);
};
