import * as React from "react";

import {
    NumericInput,
    SelectInput
} from "features/common/elements";
import { useFormContext } from "react-hook-form";

import DeleteIcon from "@mui/icons-material/Delete";
import {
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    TextField
} from "@mui/material";

import {
    ICounter,
    ICountersForm
} from "./models";

export interface IProps {
	counter: ICounter;
    index: number;
    options: any;
    onRemove: (id: number, index?: number) => void;
}

export const Counter = ({ counter, index, options, onRemove }: IProps) => {
    const {
        formState: { errors },
		register,
		control
    } = useFormContext<ICountersForm>();

	const [checked, setChecked] = React.useState(false);

	React.useEffect(() => {
		setChecked(counter.is_archived ?? false);
	}, [counter.is_archived, setChecked]);

    return (
        <Grid
            container
            spacing={4}
            sx={{ pb: 3, mb: 5, borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
            <Grid item xs={12} sm={8}>
                <SelectInput<ICountersForm>
                    label="Счетчик"
                    name={`formCounters.${index}.counter_type_id`}
                    items={options}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ textAlign: "end" }}>
                <IconButton color="error" onClick={() => onRemove(index)}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
            <Grid item xs={12} sm={8}>
                <NumericInput
                    label="Начальное показание"
                    fieldName={`formCounters.${index}.initial_value`}
                    required={true}
                    regex={/[^0-9[.]/g}
                    length={8}
                    error={!!errors.formCounters?.[index]?.initial_value}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <NumericInput
                    label="Тариф"
                    fieldName={`formCounters.${index}.tariff`}
                    required={true}
                    regex={/[^0-9[.]/g}
                    length={8}
                    error={!!errors.formCounters?.[index]?.tariff}
                />
            </Grid>
			<Grid item xs={12} sm={8}>
                <TextField
                    label="Наименование"
					inputProps={ register(`formCounters.${index}.name`) }
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControlLabel
                    control={
                        <Checkbox
							{...register(`formCounters.${index}.is_archived`)}
							checked={checked}
                            onChange={(e, checked) => setChecked(checked)}
						/>
                    }
                    label="Архив"
                />
            </Grid>
        </Grid>
    );
};
