
import { api } from "../app/api";
import { routes } from "../app/counters";
import { ICounter } from "../features/counters/models";

const countersApi = api.injectEndpoints({
	endpoints: (build) => ({
		getCounters: build.query<ICounter[], any>({
			query: (id) => ({
				method: "GET",
				url: `${routes.getForFlat}/${id}`,
			}),
		}),
		addOrUpdateCounters: build.mutation<void, ICounter[]>({
			query: (request) => ({
				method: "POST",
				url: `${routes.addOrUpdate}`,
				body: request,
			}),
		}),
		deleteCounter: build.query<void, number>({
			query: (id) => ({
				method: "DELETE",
				url: `${routes.delete}/${id}`,
			}),
		})
	}),
});

export const {
	useGetCountersQuery,
	useAddOrUpdateCountersMutation,
    useLazyDeleteCounterQuery
} = countersApi;
