import { api } from "../app/api";
import { routes } from "../app/auth";
import { IGetTokenRequest } from "./models/auth/IGetTokenRequest";
import { IGetTokenResponse } from "./models/auth/IGetTokenResponse";

const authApi = api.injectEndpoints({
    endpoints: build =>({
        generateToken: build.mutation<IGetTokenResponse, IGetTokenRequest>({
            query: (request) => ({
                method: "POST",
                url: `${routes.getToken}`,
                body: request
            }),
        })
    })
})

export const {
    useGenerateTokenMutation
} = authApi;