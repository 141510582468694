export const downloadFile = async (apiUrl: string, token: string, fileName: string) => {
    const file = await fetch(apiUrl,
    {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization" : `Token ${token}`
        }
    });

    const fileBlob = await file.blob();
    const url = URL.createObjectURL(fileBlob);

    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.target = '_blank';
    anchor.href = url;
    anchor.download = fileName;

    anchor.addEventListener("click", function(e) {
        e.stopPropagation();   
    }, { once: true });

    document.body.appendChild(anchor);

    setTimeout(function(){
        anchor.click();
        setTimeout(function(){
            URL.revokeObjectURL(url);
            document.body.removeChild(anchor);
        }, 250);
    }, 66);
}