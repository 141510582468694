import * as React from "react";

import { Grid } from "@mui/material";

import { IInvoiceService } from "../models/IInvoiceData";
import { InvoiceTableCell } from "./InvoiceTableCell";

interface IProps {
	services: IInvoiceService[];
	total_cost: number;
}

export const ServicesSection = ({ services, total_cost }: IProps) => {
	return (
		<>
			<Grid item xs={12} sm={12}>
				Услуги
			</Grid>
			<Grid item xs={12} sm={9}></Grid>
			<Grid item xs={12} sm={3}>
				Стоимость
			</Grid>
			{services.map((service, index) => {
				return (
					<React.Fragment key={index}>
						<InvoiceTableCell value={service.name} cellSize={9} />
						<InvoiceTableCell value={service.price} cellSize={3} />
					</React.Fragment>
				);
			})}
		</>
	);
};
