import { yupResolver } from "@hookform/resolvers/yup";
import * as React from "react";
import {
    useForm,
    FormProvider,
    SubmitHandler,
    useController,
} from "react-hook-form";
import { LoginValidationSchema } from "../LoginValidationSchema";
import { ILoginFormData } from "../models/ILoginFormData";

import { TextField, Grid, Button, Box } from "@mui/material";
import { useGenerateTokenMutation } from "../../../api/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { IUserToken } from "../useToken";

interface IProps {
    setToken: (userInfo: IUserToken) => void;
}

export const Login = ({ setToken }: IProps) => {
    const formMethods = useForm<ILoginFormData>({
        defaultValues: {
            login: "",
            password: "",
        },
        resolver: yupResolver(LoginValidationSchema),
        mode: "onBlur",
    });

    const {
        formState: { errors },
        control,
        handleSubmit,
    } = formMethods;

    const { field: login } = useController({ name: "login", control });
    const { field: password } = useController({ name: "password", control });

    const [username, setUsername] = React.useState("");
    const [loginUser, loginStatus] = useGenerateTokenMutation();

    const handleSave: SubmitHandler<ILoginFormData> = React.useCallback(
        (data: ILoginFormData) => {
            setUsername(data.login);
            loginUser({ username: data.login, password: data.password });
        },
        [loginUser]
    );

    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        if (loginStatus.isSuccess) {
            setToken({ token: loginStatus.data.token, username: username });
            navigate(location.pathname);
        }
    }, [loginStatus, username, setToken, location, navigate]);

    return (
        <Box sx={{display: "flex", alignItems: "center", height: "100vh", justifyContent: "center"}}>
            <FormProvider {...formMethods}>
                <Grid container spacing={2} sx={{ width: 300 }}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            {...login}
                            label="Логин"
                            fullWidth
                            error={!!errors.login}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            {...password}
                            label="Пароль"
                            type={"password"}
                            fullWidth
                            error={!!errors.password}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit(handleSave)}
                            fullWidth
                        >
                            Войти
                        </Button>
                    </Grid>
                </Grid>
            </FormProvider>
        </Box>
    );
};
