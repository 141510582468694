import * as React from "react";

import { Card } from "features/common/Card/Card";
import { useParams } from "react-router-dom";

import {
    Divider,
    Grid
} from "@mui/material";

import { useGetDataForInvoiceQuery } from "../../../api/invoiceApi";
import { CountersSection } from "./CountersSection";
import { FlatInfoSection } from "./FlatInfoSection";
import { InvoiceActions } from "./InvoiceActions";
import { InvoiceTotalRow } from "./InvoiceTotalRow";
import { ServicesSection } from "./ServicesSection";

export const Invoice = () => {
	const { id } = useParams();
	const { data: invoiceData } = useGetDataForInvoiceQuery(id);

	if (!invoiceData) {
		return null;
	}

	const dividerStyle = {
		width: 400,
		mt: 4,
		mb: 2,
		"border-color": "rgba(0, 0, 0, 0.1)",
	};

	return (
		<Card>
			<Grid container spacing={0.5} sx={{ width: 400 }}>
				<FlatInfoSection
					date={invoiceData.date}
					flatInfo={invoiceData.flat_info}
				/>
				<Divider sx={{ ...dividerStyle }} />
				<CountersSection
					counters={invoiceData.counters}
				/>
				<Divider sx={{ ...dividerStyle }} />
				<ServicesSection
					services={invoiceData.services}
					total_cost={invoiceData.services_cost}
				/>
				<Divider sx={{ ...dividerStyle }} />
				<InvoiceTotalRow title="Комиссия" value={invoiceData.comission_cost} />
				<InvoiceTotalRow title="Подытог" value={invoiceData.counters_cost + invoiceData.services_cost} />
				<Divider sx={{ ...dividerStyle }} />
				<InvoiceTotalRow
					title="Итого"
					value={invoiceData.total_cost}
				/>
			</Grid>
			<InvoiceActions />
		</Card>
	);
};
