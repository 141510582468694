import { api } from "../app/api";
import { routes } from "../app/services-api";
import { IService } from "../features/services/models";

const servicesApi = api.injectEndpoints({
	endpoints: (build) => ({
		getServices: build.query<IService[], any>({
			query: (id) => ({
				method: "GET",
				url: `${routes.getForFlat}/${id}`,
			})
		}),
		addOrUpdateServices: build.mutation<void, IService[]>({
			query: (request) => ({
				method: "POST",
				url: `${routes.addOrUpdate}`,
				body: request
			})
		}),
		deleteService: build.query<void, number>({
			query: (id) => ({
				method: "DELETE",
				url: `${routes.delete}/${id}`
			})
		})
	})
});

export const {
	useGetServicesQuery,
	useAddOrUpdateServicesMutation,
    useLazyDeleteServiceQuery
} = servicesApi;
