import { IReadoutUpdateRequest } from "features/readouts/models/IReadoutUpdateRequest";

import { api } from "../app/api";
import { routes } from "../app/readouts-api";
import { IReadoutsHistory } from "../features/readouts/models/IReadoutsHistory";
import {
    IAddReadoutsRequest,
    IReadouts
} from "./models/readouts";

const readoutsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getReadoutsCounters: build.query<IReadouts, any>({
            query: (id) => ({
                method: "GET",
                url: `${routes.getCounters}/${id}`,
            }),
        }),
        addReadouts: build.mutation<void, IAddReadoutsRequest>({
            query: (request) => ({
                method: "POST",
                url: `${routes.addReadouts}`,
                body: request,
            }),
        }),
        getReadoutsHistory: build.query<IReadoutsHistory[], any>({
            query: (id) => ({
                method: "GET",
                url: `${routes.getReadoutsHistory}/${id}`,
            }),
        }),
        updateReadout: build.mutation<void, IReadoutUpdateRequest>({
            query: (request) => ({
                method: "POST",
                url: `${routes.updateReadout}`,
                body: request,
            }),
        }),
    }),
});

export const {
    useGetReadoutsCountersQuery,
    useAddReadoutsMutation,
    useUpdateReadoutMutation,
    useGetReadoutsHistoryQuery
} = readoutsApi;
