import * as React from "react";
import { InvoiceTableCell } from "./InvoiceTableCell";

interface IProps {
    title: string;
    value: number;
}

export const InvoiceTotalRow = ({ title, value } : IProps) => {
	return (
		<>
			<InvoiceTableCell value={title} cellSize={9} />
			<InvoiceTableCell value={value} cellSize={3} />
		</>
	);
};
