import * as React from "react";

import { IconButton } from "common/components";
import { useParams } from "react-router-dom";

import { useToken } from "../../auth/useToken";
import { downloadFile } from "../../helpers/download-file-helper";

export const InvoiceActions = () => {
    const { id } = useParams();
    const { token } = useToken();

    const handleClick = React.useCallback(async () => {
        await downloadFile(
            `${process.env.REACT_APP_API_URL}api/invoice/download/${id}`,
            token,
            "Счет.pdf"
        );
    }, [id, token]);

    return (
        <IconButton
			label="Скачать"
			type="pdf"
			onClick={handleClick}
		/>
    );
};
