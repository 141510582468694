import * as React from "react";

import { Card } from "features/common/Card/Card";
import moment from "moment";
import {
    FormProvider,
    SubmitHandler,
    useFieldArray,
    useForm
} from "react-hook-form";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
    Box,
    Button,
    Grid,
    Typography
} from "@mui/material";

import { IAddReadoutsRequest } from "../../../api/models/readouts";
import {
    useAddReadoutsMutation,
    useGetReadoutsCountersQuery
} from "../../../api/readoutsApi";
import { IReadoutsCountersForm } from "../models/IReadoutsCountersForm";
import { ReadoutsCardValidationSchema } from "../ReadoutsCardValidationSchema";
import { Readout } from "./Readout";
import { ReadoutsActions } from "./ReadoutsActions";

//TODO: беда с неймингом

export const ReadoutsCard = () => {
    const { id } = useParams();
    const { data: readouts, refetch } = useGetReadoutsCountersQuery(id);

    const formMethods = useForm<IReadoutsCountersForm>({
        defaultValues: {
            formData: [],
        },
        resolver: yupResolver(ReadoutsCardValidationSchema),
        mode: "onBlur",
    });

    const {
        formState: { errors },
        control,
        handleSubmit,
        reset,
    } = formMethods;

    const { fields } = useFieldArray({
        control,
        name: "formData",
        keyName: "guid",
    });

    React.useEffect(() => {
        if (!!readouts && !readouts?.message) {
            reset({ formData: readouts?.counters });
        }
    }, [readouts, reset]);

    const [addReadouts] = useAddReadoutsMutation();

    const handleSave: SubmitHandler<IReadoutsCountersForm> = React.useCallback(
        async (data: IReadoutsCountersForm) => {
            const request: IAddReadoutsRequest = {
                date: moment(new Date()).format("YYYY-MM-DD"),
                flat_id: Number(id),
                counters: data.formData,
            };

            await addReadouts(request);
            refetch();
        },
        [id, addReadouts, refetch]
    );

    if (!readouts) {
        return null;
    }

    let contentMode = 2;

	if (!readouts.is_counters_exists){
		contentMode = 0;
	}

	if (!!readouts.message){
		contentMode = 1;
	}

    return (
        <Card>
            <FormProvider {...formMethods}>
                <Typography
                    sx={{
                        fontSize: 18,
                        paddingBottom: 4,
                    }}
                    color="text.primary"
                    gutterBottom
                >
                    Показания
                </Typography>
                {contentMode === 0 && (
                    <>Для подачи показаний необходимо добавить счетчики</>
                )}
                {contentMode === 1 && <>{readouts.message}</>}
                {contentMode === 2 && (
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <Box>{moment(new Date()).format("DD.MM.YYYY")}</Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {fields.map((field, index) => {
                                return (
                                    <Readout
                                        key={field.guid}
                                        index={index}
                                        counter={field}
                                    />
                                );
                            })}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button
                                variant="contained"
                                type="submit"
                                onClick={handleSubmit(handleSave)}
                                disabled={!!errors.formData}
                                fullWidth
                            >
                                Сохранить
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </FormProvider>
            {!readouts.is_first && <ReadoutsActions />}
        </Card>
    );
};
