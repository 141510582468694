import {
    api,
    TagTypes
} from "../app/api";
import { routes } from "../app/flat-avatar-api";
import { IChangeAvatarRequest } from "./models/flat-avatar/IChangeAvatarRequest";
import { IFlatAvatar } from "./models/flat-avatar/IFlatAvatar";

const flatAvatarApi = api.injectEndpoints({
    endpoints: build =>({
        getForFlat: build.query<IFlatAvatar, any>({
            query: (id) => ({
                url: `${routes.get}${id}`
            }),
            providesTags: [TagTypes.FlatAvatar]
        }),
        changeAvatar: build.mutation<void, IChangeAvatarRequest>({
            query: (request) => ({
                method: "POST",
                url: `${routes.change}`,
                body: request,
            }),
            invalidatesTags: [TagTypes.FlatAvatar]
        }),
    })
})

export const {
    useGetForFlatQuery,
    useChangeAvatarMutation,
} = flatAvatarApi;
