import { IComissionSetting } from "features/settings/Comission/IComissionSetting";
import { IReadoutsNotificationSetting } from "features/settings/ReadoutsNotification";
import { IReadoutsReminderSetting } from "features/settings/ReadoutsReminder";

import {
    api,
    TagTypes
} from "../app/api";
import { routes } from "../app/settings-api";

const settingsApi = api.injectEndpoints({
    endpoints: build =>({
        getReadoutsNotifcationSetting: build.query<IReadoutsNotificationSetting, any>({
            query: id =>({
                method: "GET",
                url: `${routes.getReadoutsNotificationSettings}/${id}`
            }),
            providesTags: [TagTypes.ReadoutsNotificationsSettings]
        }),
        addReadoutsNotifcation: build.mutation<void, IReadoutsNotificationSetting>({
            query: (request) =>({
                url: `${routes.addReadoutsNotificationSettings}`,
                method: "POST",
                body: request
            }),
            invalidatesTags: [TagTypes.ReadoutsNotificationsSettings]
        }),
        getReadoutsReminderSetting: build.query<IReadoutsReminderSetting, any>({
            query: id =>({
                method: "GET",
                url: `${routes.getReadoutsReminderSetting}/${id}`
            }),
            providesTags: [TagTypes.ReadoutsRemindersSettings]
        }),
        addReadoutsReminderSetting: build.mutation<void, IReadoutsReminderSetting>({
            query: (request) =>({
                url: `${routes.addReadoutsReminderSetting}`,
                method: "POST",
                body: request
            }),
            invalidatesTags: [TagTypes.ReadoutsRemindersSettings]
        }),
        getComissionSetting: build.query<IComissionSetting, any>({
            query: id =>({
                method: "GET",
                url: `${routes.getComissionSetting}/${id}`
            }),
            providesTags: [TagTypes.ComissionSettings]
        }),
        addComissionSetting: build.mutation<void, IComissionSetting>({
            query: (request) =>({
                url: `${routes.addComissionSetting}`,
                method: "POST",
                body: request
            }),
            invalidatesTags: [TagTypes.ComissionSettings]
        })
    })
})

export const {
    useGetReadoutsNotifcationSettingQuery,
    useAddReadoutsNotifcationMutation,
    useGetReadoutsReminderSettingQuery,
    useAddReadoutsReminderSettingMutation,
    useGetComissionSettingQuery,
    useAddComissionSettingMutation,
} = settingsApi;
