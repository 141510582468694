import * as React from "react";

import {
    useAddOrUpdateCountersMutation,
    useGetCountersQuery,
    useLazyDeleteCounterQuery
} from "api/countersApi";
import { useGetCounterTypesQuery } from "api/counterTypesApi";
import { Card } from "features/common/Card/Card";
import { ISelectItem } from "features/common/models";
import { Status } from "features/common/Status/Status";
import {
    FormProvider,
    SubmitHandler,
    useFieldArray,
    useForm
} from "react-hook-form";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
    Button,
    Grid,
    Typography
} from "@mui/material";

import { Counter } from "./Counter";
import { CountersActions } from "./CountersActions";
import { CountersCardValidationSchema } from "./CountersCardValidationSchema";
import { getChangedCounters } from "./helpers";
import {
    ICounter,
    ICountersForm
} from "./models";

export const CountersCard = () => {
    const { id } = useParams();
    const { data: existedCounters, refetch } = useGetCountersQuery(id);

    const formMethods = useForm<ICountersForm>({
        defaultValues: {
            formCounters: [],
        },
        resolver: yupResolver(CountersCardValidationSchema),
        mode: "onBlur",
    });

    const { control, handleSubmit, reset } = formMethods;

    const { fields, append, remove } = useFieldArray({
        control,
        name: "formCounters",
        keyName: "guid",
    });

    const DEFAULT_COUNTER = React.useMemo(() => {
        return {
            id: 0,
            name: "",
            counter_type_id: -1,
            initial_value: 0,
            tariff: 0,
            is_archived: false,
            flat_id: Number(id),
        } as ICounter;
    }, [id]);

    React.useEffect(() => {
        if (!!existedCounters) {
            reset({ formCounters: existedCounters });
        }
    }, [existedCounters, reset]);

    const addCounter = React.useCallback(() => {
        append(DEFAULT_COUNTER);
    }, [append, DEFAULT_COUNTER]);

    const [deleteCounter] = useLazyDeleteCounterQuery();

    const removeCounter = React.useCallback(
        async (index?: number) => {
            if (index !== undefined) {
                remove(index);

                if (!!fields[index].id) {
                    await deleteCounter(fields[index].id);
                    refetch();
                }
            }
        },
        [fields, remove, deleteCounter, refetch]
    );

    const { data: counterTypes } = useGetCounterTypesQuery();

    const selectItems: ISelectItem[] = React.useMemo(
        () =>
            counterTypes?.map((item) => ({
                name: item.name,
                value: item.id,
                id: item.id,
            })) ?? [],
        [counterTypes]
    );

    const [addOrUpdateCounters, updateStatus] = useAddOrUpdateCountersMutation();

    const handleSave: SubmitHandler<ICountersForm> = React.useCallback(
        async (data: ICountersForm) => {
            const changedCounters = getChangedCounters(
                data?.formCounters,
                existedCounters
            );
            if (!!changedCounters && changedCounters.length > 0) {
                await addOrUpdateCounters(data.formCounters);
                refetch();
            }
        },
        [existedCounters, addOrUpdateCounters, refetch]
    );

    if (!counterTypes) {
        return null;
    }

    return (
        <Card>
            <FormProvider {...formMethods}>
                <Typography
                    sx={{
                        fontSize: 18,
                        paddingBottom: 4,
                    }}
                    color="text.primary"
                    gutterBottom
                >
                    Счетчики
                </Typography>
                {fields.length === 0 ? (
                    <>Вы не добавили еще ни одного счетчика</>
                ) : (
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            {fields.map((field, index) => {
                                return (
                                    <Counter
                                        counter={field}
                                        key={field.guid}
                                        index={index}
                                        options={selectItems}
                                        onRemove={removeCounter}
                                    />
                                );
                            })}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button
                                variant="contained"
                                type="submit"
                                onClick={handleSubmit(handleSave)}
                                fullWidth
                            >
                                Сохранить
                            </Button>
                        </Grid>
                    </Grid>
                )}
                <Status
                    initialState={
                        updateStatus.isSuccess || updateStatus.isError
                    }
                    isError={updateStatus.isError}
                />
            </FormProvider>
            <CountersActions handleAddCounter={addCounter} />
        </Card>
    );
};
