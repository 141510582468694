import * as React from "react";

import moment from "moment";

import { Grid } from "@mui/material";

import { IInvoiceFlatInfo } from "../models/IInvoiceData";
import { InvoiceTableCell } from "./InvoiceTableCell";
import { SubDivider } from "./SubDivider";

interface IProps {
	date: string;
	flatInfo: IInvoiceFlatInfo;
}

export const FlatInfoSection = ({ date, flatInfo }: IProps) => {
	return (
		<>
			<Grid item xs={12} sm={9}>
				{flatInfo.name}
			</Grid>
			<Grid item xs={12} sm={3}>
				{moment(date).format("DD.MM.YYYY")}
			</Grid>
			<SubDivider />
			<InvoiceTableCell value="Арендная плата" cellSize={9} />
			<InvoiceTableCell value={flatInfo.price} cellSize={3} />
		</>
	);
};
