import * as React from "react";
import { Box, Grid } from "@mui/material";

interface IProps {
    value: number | string;
    cellSize: number;
} 

export const InvoiceTableCell = ({ value, cellSize } : IProps) => {
    const cellStyle = {
        border: 1,
        borderColor: 'text.primary',
        p: 1
    };

    return (
        <Grid item xs={12} sm={cellSize}>
			<Box sx={{ ...cellStyle }}>{value}</Box>
		</Grid>
    );
}