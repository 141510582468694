import * as React from "react";

import backwardIcon from "img/backward.png";
import counterIcon from "img/counter.png";
import editIcon from "img/edit.png";
import eventIcon from "img/event.png";
import newFlat from "img/flat_new.png";
import invoiceIcon from "img/invoice.png";
import pdfIcon from "img/pdf.png";
import readoutsIcon from "img/readouts.png";
import removeIcon from "img/remove.png";
import serviceIcon from "img/service.png";

import { Box } from "@mui/material";

export type IconType =
	| "newFlat"
	| "counter"
	| "service"
	| "edit"
	| "remove"
	| "invoice"
	| "pdf"
	| "event"
	| "readouts"
	| "backward";

const iconType: { [it in IconType]: any } = {
	newFlat: newFlat,
	counter: counterIcon,
	service: serviceIcon,
	edit: editIcon,
	remove: removeIcon,
	invoice: invoiceIcon,
	pdf: pdfIcon,
	event: eventIcon,
	readouts: readoutsIcon,
	backward: backwardIcon,
};

interface IProps {
	type?: IconType;
	path?: string;
	width?: number;
	height?: number;
	label?: string;
	onClick: () => void;
}

export const IconButton = ({
	type,
	path,
	width,
	height,
	label,
	onClick,
}: IProps) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				paddingBottom: "20px",
				cursor: "pointer",
			}}
			onClick={onClick}
		>
			<>
				{!!type ? (
					<img
						alt={label ?? ""}
						src={iconType[type]}
						width={width ?? "64"}
						height={height ?? "64"}
					/>
				) : (
					<img
						alt={label ?? ""}
						src={path}
						width={width}
						height={height}
					/>
				)}
			</>
			<>
				<label>{label}</label>
			</>
		</Box>
	);
};
