import { api } from "../app/api";
import { routes } from "../app/services-types";
import { IServicesTypes } from "../features/services/models";

const servicesTypesApi = api.injectEndpoints({
    endpoints: build =>({
         getServicesTypes: build.query<IServicesTypes[], void>({
            query: () =>({
                method: "GET",
                url: routes.getAll
            })
        }),
    })
})

export const {
    useGetServicesTypesQuery
} = servicesTypesApi;