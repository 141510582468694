import * as React from "react";
import { Divider } from "@mui/material";

export const SubDivider = () => {
    const dividerStyle = {
		width: 400, mt: 2, "border-color": "rgba(0, 0, 0, 0)"
	};
    return (
        <Divider sx={{...dividerStyle}} />
    );
}