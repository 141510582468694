import * as React from "react";

import {
    useAddReadoutsReminderSettingMutation,
    useGetReadoutsReminderSettingQuery
} from "api/settingsApi";
import { Status } from "features/common/Status/Status";
import {
    FormProvider,
    SubmitHandler,
    useController,
    useForm
} from "react-hook-form";
import { useParams } from "react-router-dom";

import {
    Button,
    Stack,
    TextField,
    Typography
} from "@mui/material";

import { IReadoutsReminderSetting } from "./models/IReadoutsReminderSetting";

// TODO:
// 1. Сделать шаг минут - минимум 5, а то и 30 минут

export const ReadoutsReminder = () => {
	const { id } = useParams();

	const formMethods = useForm<IReadoutsReminderSetting>({
		defaultValues: {
			tg_user_id: "",
			days_before: 0,
			remind_time: "12:00"
		},
		mode: "onBlur",
	});

	const {
		formState: { errors },
		control,
		handleSubmit,
		reset
	} = formMethods;

	const { field: tg_user_id } = useController({
		name: "tg_user_id",
		control,
	});
	const { field: days_before } = useController({
		name: "days_before",
		control,
	});
	const { field: remind_time } = useController({
		name: "remind_time",
		control,
	});

	const { data: setting, isSuccess } = useGetReadoutsReminderSettingQuery(id);

	React.useEffect(() => {
		if (!!isSuccess && !!setting.tg_user_id){
			reset(setting);
		}
	}, [isSuccess, setting]);

	const [addSetting, status] = useAddReadoutsReminderSettingMutation();

	const handleSave: SubmitHandler<IReadoutsReminderSetting> =
		React.useCallback(
			(data: IReadoutsReminderSetting) => {
				addSetting({
					flat_id: id,
					tg_user_id: data.tg_user_id,
					days_before: data.days_before,
					remind_time: data.remind_time
				});
			},
			[id, addSetting]
		);

	return (
		<FormProvider {...formMethods}>
			<Stack spacing={4} sx={{ width: "550px" }}>
				<Stack spacing={2}>
					<Typography variant="h5">
						Настройка напоминания о подаче показаний
					</Typography>
					<Typography variant="subtitle1">
						Необходимо указать id телеграмм-пользователя, которому
						будет приходить напоминание о подошедшем сроке подачи
						показаний.
					</Typography>
				</Stack>
				<Stack spacing={2}>
					<TextField
						label="Id телеграмм-пользователя"
						{...tg_user_id}
						required
					/>
					<TextField
						label="За сколько дней напомнить"
						type="number"
						{...days_before}
						required
					/>
					<TextField
						label="Во сколько уведомить"
						type="time"
						{...remind_time}
						InputLabelProps={{ shrink: true }}
						required
					/>
					<Button
						variant="contained"
						type="submit"
						onClick={handleSubmit(handleSave)}
					>
						Сохранить
					</Button>
				</Stack>
				<Status
					initialState={status.isSuccess || status.isError}
					isError={status.isError}
				/>
			</Stack>
		</FormProvider>
	);
};
