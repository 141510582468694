import * as React from "react";

import {
    Path,
    useController,
    useFormContext
} from "react-hook-form";

import { TextField } from "@mui/material";

interface IProps {
    label: string;
    fieldName: string;
    required?: boolean;
    regex: RegExp;
    length: number;
    error?: boolean;
    helperText?: string;
}

export const NumericInput = <T extends {}>({ label, fieldName, required, regex, length, error, helperText } : IProps) => {
    const { control } = useFormContext<T>();
    const { field } = useController<T>({ control, name: fieldName as Path<T>});
    
    const handleOnInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.replace(regex, "").slice(0, length);
    }, [regex, length]);
        
    return(
        <TextField
            {...field}
            label={label}
            name={fieldName as Path<T>}
            required={required ?? false}
            onInput={handleOnInputChange}
            error={error}
            value={field.value ?? 0}
            helperText={error ?? helperText}
        />
    );
};
