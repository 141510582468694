import * as React from "react";

import { IAddReadoutsRequest } from "api/models/readouts";
import { IconButton } from "common/components";
import { Card } from "features/common/Card/Card";
import moment from "moment";
import {
    FormProvider,
    SubmitHandler,
    useFieldArray,
    useForm
} from "react-hook-form";
import { useParams } from "react-router-dom";

import {
    Box,
    Button,
    Grid,
    Stack,
    TextField,
    Typography
} from "@mui/material";

import {
    useGetReadoutsHistoryQuery,
    useUpdateReadoutMutation
} from "../../../api/readoutsApi";
import { IReadoutsCountersForm } from "../models/IReadoutsCountersForm";
import { IReadoutsHistory } from "../models/IReadoutsHistory";
import { IReadoutUpdateRequest } from "../models/IReadoutUpdateRequest";

export const ReadoutsHistory = () => {
	const { id } = useParams();
	const { data: readoutsHistory, refetch } = useGetReadoutsHistoryQuery(id);

	const [activeReadout, setActiveReadout] =
		React.useState<IReadoutsHistory | null>(null);

	const [isEditMode, setIsEditMode] = React.useState(false);

	const formMethods = useForm<IReadoutsCountersForm>({
        defaultValues: {
            formData: [],
        }
    });

    const {
        formState: { errors },
        control,
        handleSubmit,
        reset,
		register,
    } = formMethods;

    const { fields } = useFieldArray({
        control,
        name: "formData",
        keyName: "guid",
    });

	React.useEffect(() => {
		if (!!readoutsHistory) {
			setActiveReadout(readoutsHistory[0]);
			reset({ formData: readoutsHistory[0].counters });
		}
	}, [readoutsHistory, setActiveReadout, reset]);

	const handleClick = React.useCallback(
		(index: number) => {
			setActiveReadout(readoutsHistory[index]);
			reset({ formData: readoutsHistory[index].counters });
		},
		[readoutsHistory, reset, setActiveReadout]
	);

	const handleInvoiceClick = React.useCallback(
		(readout_id: number) => {
			window.location.href = `/invoice/${readout_id}`;
		},
		[id]
	);

	const handleEditReadoutClick = React.useCallback(
		() => {
			setIsEditMode(true);
		},
		[setIsEditMode]
	);

	const [updateReadout, isSuccess] = useUpdateReadoutMutation();

	const handleSave: SubmitHandler<IReadoutsCountersForm> = React.useCallback(
        async (data: IReadoutsCountersForm) => {
			console.log(data.formData);
            const request: IReadoutUpdateRequest = {
				flat_id: Number(id),
				readout_id: activeReadout.id,
                counters: data.formData
            };

            await updateReadout(request);
            
        },
        [id, updateReadout, refetch, activeReadout?.id]
    );

	React.useEffect(() => {
		if (isSuccess){
			setIsEditMode(false);
			refetch();
		}
	}, [isSuccess, refetch]);

	if (!activeReadout) {
		return null;
	}

	return (
		<Card>
			<>
				<Grid container spacing={4}>
					<Grid item xs={12} sm={12}>
						<Typography
							sx={{
								fontSize: 18,
								paddingBottom: 4,
							}}
							color="text.primary"
							gutterBottom
						>
							История показаний
						</Typography>
					</Grid>
				</Grid>
				<Stack
					direction={"row"}
					alignItems={"baseline"}
					justifyContent={"space-between"}
				>
					<Box>{moment(activeReadout.date).format("DD.MM.YYYY")}</Box>
					<Stack
						direction={"row"}
						width={"100px"}
						justifyContent={"space-between"}
					>
					<IconButton
						type="invoice"
						width={40}
						height={40}
						onClick={() => handleInvoiceClick(activeReadout.id)}
					/>
					<IconButton
						type="edit"
						width={40}
						height={40}
						onClick={handleEditReadoutClick}
					/>
					</Stack>
				</Stack>
				<FormProvider {...formMethods}>
					<Grid container spacing={4}>
						{fields.map((field, index) => (
							<Grid item xs={12} sm={12} key={index}>
								<TextField
									key={field.guid}
									label={field.type_name + (!!field.name ? ` (${field.name})` : "" )}
									inputProps={ register(`formData.${index}.value`) }
									disabled={!isEditMode}
									fullWidth
								/>
							</Grid>
						))}
						{
							isEditMode &&
							<Grid item xs={12} sm={12}>
								<Button
									variant="contained"
									type="submit"
									onClick={handleSubmit(handleSave)}
									disabled={!isEditMode}
									fullWidth
								>
									Сохранить
								</Button>
							</Grid>
						}
					</Grid>
				</FormProvider>
			</>
			<Stack
				direction={"column"}
				spacing={2}
				sx={{
					maxHeight: "350px",
					overflowY: "auto",
					pl: 2.5,
					"&::-webkit-scrollbar": {
						width: "5px",
					},
					"&::-webkit-scrollbar-track": {
						background: "#f1f1f1",
					},
					"&::-webkit-scrollbar-thumb": {
						backgroundColor: "#888",
						borderRadius: "5px",
					},
				}}
			>
				{readoutsHistory.map((readout, index) => {
					return (
						<Stack key={index}>
							<Button onClick={() => handleClick(index)}>
								{moment(readout.date).format("DD.MM.YYYY")}
							</Button>
						</Stack>
					);
				})}
			</Stack>
		</Card>
	);
};
