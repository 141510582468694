import * as React from "react";

import { IconButton } from "common/components";
import { useParams } from "react-router-dom";

export const ReadoutsActions = () => {
    const { id } = useParams();
    const handleHistoryClick = React.useCallback(() => {
        window.location.href = "/readouts/history/" + id;
    }, [id]);

    const handleInvoiceClick = React.useCallback(() => {
        window.location.href = "/invoice/" + id;
    }, [id]);

    return (
        <>
            <IconButton
                label="История"
                type="event"
                onClick={handleHistoryClick}
            />
        </>
    );
};
