import * as React from "react";

import {
    useAddReadoutsNotifcationMutation,
    useGetReadoutsNotifcationSettingQuery
} from "api/settingsApi";
import { Status } from "features/common/Status/Status";
import { useParams } from "react-router-dom";

import {
    Button,
    Stack,
    TextField,
    Typography
} from "@mui/material";

export const ReadoutsNotification = () => {
    const { id } = useParams();
    
    const { data: notifcationSetting, isSuccess } = useGetReadoutsNotifcationSettingQuery(id);
    const [value, setValue] = React.useState("");

    React.useEffect(() => {
        if (!!isSuccess) {
            setValue(notifcationSetting.tg_user_ids);
        }
    }, [notifcationSetting]);

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
        }, [setValue]
    );

    const [addNotification, status] = useAddReadoutsNotifcationMutation();

    const handleClick = React.useCallback(() => {
        addNotification({ flat_id: id, tg_user_ids: value });
    }, [value, addNotification]);

    return (
        <Stack spacing={4} sx={{ width: "550px" }}>
            <Stack spacing={2}>
                <Typography variant="h5">
                    Настройка уведомления о подаче показаний
                </Typography>
                <Typography variant="subtitle1">
                    Необходимо указать id телеграмм-пользователей (через "точку
                    с запятой"), которомым будут приходить уведомления при
                    успешной подаче показаний.
                </Typography>
            </Stack>
            <Stack spacing={2}>
                <TextField
                    label="id телеграмм-пользователей"
                    value={value}
                    onChange={handleChange}
                />
                <Button variant="contained" onClick={handleClick}>
                    Сохранить
                </Button>
            </Stack>
            <Status
                initialState={status.isSuccess || status.isError}
                isError={status.isError}
            />
        </Stack>
    );
};

