import * as React from "react";

import { IconButton } from "common/components";
import { IFlatListItem } from "features/flat/models/IFlatsListItem";

import { Box } from "@mui/material";

export interface IProps {
    flat: IFlatListItem;
}

export const FlatListItem = ({ flat }: IProps) => {
    const src = "data:image/png;base64, " + flat.avatar;

    const handleClick = React.useCallback(() => {
        window.location.href = `/flat/${flat.id}`;
    }, [flat]);

    return (
        <Box sx={{ padding: "40px" }}>
            <IconButton
                label={flat.name}
                onClick={handleClick}
                path={src}
                width={128}
                height={128}
            />
        </Box>
    );
};
