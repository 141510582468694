import * as React from "react";

import { useController, UseControllerProps, useFormContext } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { ISelectItem, ValueType } from "../models";

export interface IProps {
    items: ISelectItem[];
}

export const SelectInput = <T extends {}>(props : UseControllerProps<T> & SelectProps<ValueType> & IProps) => {
    const { control } = useFormContext<T>();
    const { field } = useController<T>({ control, name: props.name});
    
    const onChange = React.useCallback((event: SelectChangeEvent<string | number | readonly string[]>) => {
        field.onChange(event.target.value);
    }, [field]);
        
    return (
      <FormControl fullWidth>
          {props.label &&
              <InputLabel id={field.name}>{props.label}</InputLabel>
          }
          <Select
              {...field}
              labelId={field.name}
              onChange={onChange}
              error={props.error}
              fullWidth={props.fullWidth}
              value={field.value as ValueType}
              renderValue={props.renderValue}
              label={props.label}
              disabled={props.disabled}
          >
              <MenuItem value="-1" disabled>
                  Выберите...
              </MenuItem>
              {
                  props.items.map((item, index) => (
                     <MenuItem
                        key={index}
                        value={item.value}
                     >
                         {item.name}
                     </MenuItem> 
                  ))
              }
          </Select>
      </FormControl>  
    );
};