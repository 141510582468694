import { api } from "../app/api";
import { routes } from "../app/counter-types";
import { ICounterType } from "../features/counters/models/ICounterType";

const counterTypesApi = api.injectEndpoints({
    endpoints: build =>({
         getCounterTypes: build.query<ICounterType[], void>({
            query: () =>({
                method: "GET",
                url: routes.getForFlat
            })
        }),
    })
})

export const {
    useGetCounterTypesQuery
} = counterTypesApi;