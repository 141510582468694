import * as React from 'react';

export interface IUserToken{
    username: string;
    token: string;
}

export const useToken = () => {
  const getToken = () => {
    const tokenString = localStorage.getItem("flareca_token");
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  };

  const [token, setToken] = React.useState(getToken());

  const saveToken = (userToken: IUserToken) => {
    localStorage.setItem("flareca_token",JSON.stringify(userToken));
    setToken(JSON.stringify(userToken));
  };

  return {
    setToken: saveToken,
    token
  }
}