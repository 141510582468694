import * as React from "react";

import { Grid } from "@mui/material";

import { IInvoiceCounter } from "../models/IInvoiceData";
import { InvoiceTableCell } from "./InvoiceTableCell";
import { SubDivider } from "./SubDivider";

interface IProps {
	counters: IInvoiceCounter[];
}

export const CountersSection = ({ counters }: IProps) => {
	return (
		<>
			<Grid item xs={12} sm={12}>
				Счетчики
			</Grid>
			<Grid item xs={12} sm={3}></Grid>
			<Grid item xs={12} sm={3}>
				Тариф
			</Grid>
			<Grid item xs={12} sm={3}>
				Расход
			</Grid>
			<Grid item xs={12} sm={3}>
				Стоимость
			</Grid>
			{counters.map((counter, index) => {
				return (
					<React.Fragment key={index}>
						<InvoiceTableCell value={counter.type} cellSize={3} />
						<InvoiceTableCell value={counter.tariff} cellSize={3} />
						<InvoiceTableCell value={counter.diff} cellSize={3} />
						<InvoiceTableCell value={counter.price} cellSize={3} />
					</React.Fragment>
				);
			})}
            <SubDivider />
		</>
	);
};
