import * as React from "react";

import { IconButton } from "common/components";

export interface IProps {
    handleAddCounter: () => void;
}

export const CountersActions = ({ handleAddCounter }: IProps) => {
    return (
        <IconButton
            label="Добавить"
            type="counter"
            onClick={handleAddCounter}
        />
    );
};
