import { IService } from "./models";

function getChangedServices(first?: IService[], second?: IService[]){
    return findDifference(changedCondition, first, second);
}

function changedCondition(a: IService, b: IService) {
    return (a.id === b.id || a.id === 0)
            && a.flat_id === b.flat_id
            && (a.service_type_id !== b.service_type_id
                || a.price !== b.price);
}

function findDifference(compareFunction: (a : IService, b: IService) => void, first?: IService[], second?: IService[]) {
    if (!!first?.length && !second?.length) {
        return first;
    }
    
    return first?.filter(leftValue => 
        second?.some(rightValue => 
            compareFunction(leftValue, rightValue)));
}

export { getChangedServices }