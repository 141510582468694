import * as React from "react";

import { IconButton } from "common/components";
import { useParams } from "react-router-dom";

export const FlatActions = () => {
    const { id } = useParams();

    const handleCountersClick = React.useCallback(() => {
        window.location.href = "/counters/" + id;
    }, [id]);

    const handleServicesClick = React.useCallback(() => {
        window.location.href = "/services/" + id;
    }, [id]);

    const handleReadoutsClick = React.useCallback(() => {
        window.location.href = "/readouts/" + id;
    }, [id]);

    return (
        <>
            <IconButton
                label="Счетчики"
                type="counter"
                onClick={handleCountersClick}
            />
            <IconButton
                label="Услуги"
                type="service"
                onClick={handleServicesClick}
            />
            <IconButton
                label="Показания"
                type="readouts"
                onClick={handleReadoutsClick}
            />
        </>
    );
};
