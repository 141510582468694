import * as Yup from "yup";

export const CountersCardValidationSchema = Yup.object().shape({
    formCounters: Yup.array().of(
        Yup.object().shape({
            initial_value: Yup.number().required("Поле является обязательным"),
            tariff: Yup.number().required("Поле является обязательным")
        })
    )
});
