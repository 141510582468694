import { ICounter } from "./models";

function getChangedCounters(first?: ICounter[], second?: ICounter[]){
    return findDifference(changedCondition, first, second);
}

function changedCondition(a: ICounter, b: ICounter) {
    return (a.id === b.id || a.id === 0)
            && a.flat_id === b.flat_id
            && (a.counter_type_id !== b.counter_type_id
                || a.initial_value !== b.initial_value
                || a.tariff !== b.tariff
                || a.is_archived !== b.is_archived
                || a.name !== b.name
            );
}

function findDifference(compareFunction: (a : ICounter, b: ICounter) => void, first?: ICounter[], second?: ICounter[]) {
    if (!!first?.length && !second?.length) {
        return first;
    }
    
    return first?.filter(leftValue => 
        second?.some(rightValue => 
            compareFunction(leftValue, rightValue)));
}

export { getChangedCounters }