import { IFlatListItem } from "features/flat/models/IFlatsListItem";

import {
    api,
    TagTypes
} from "../app/api";
import { routes } from "../app/flats-api-routes";

const flatsApi = api.injectEndpoints({
    endpoints: build =>({
        getFlats: build.query<IFlatListItem[], void>({
            query: () => ({
                url: routes.getFlats
            }),
            providesTags: [TagTypes.FlatAvatar]
        }),
    })
})

export const {
    useGetFlatsQuery,
} = flatsApi;
