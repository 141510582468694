import React from "react";

import "./App.css";
import "./css/main/style.css";

import { Settings } from "features";
import { CountersCard } from "features/counters/CountersCard";
import { FlatCard } from "features/flat/components/FlatCard/FlatCard";
import { FlatsList } from "features/flats/components/FlatsList";
import {
    Route,
    Routes
} from "react-router-dom";

import { Login } from "./features/auth/components/Login";
import { useToken } from "./features/auth/useToken";
import { Error } from "./features/errors/components/Error";
import { Invoice } from "./features/invoice/components/Invoice";
import { ReadoutsCard } from "./features/readouts/components/ReadoutsCard";
import { ReadoutsHistory } from "./features/readouts/components/ReadoutsHistory";
import { ServicesCard } from "./features/services/components/ServicesCard";

export function App() {
    const { token, setToken } = useToken();

    if (!token) {
        return <Login setToken={setToken} />;
    }

    return (
        <main className="App">
            <Routes>
                {!!token && (
                        <>
                            <Route path="/" element={<FlatsList />} />
                            <Route path="/flat/:id" element={<FlatCard />} />
                            <Route path="/flat/new" element={<FlatCard />} />
                            <Route path="/counters/:id" element={<CountersCard />} />
                            <Route path="/services/:id" element={<ServicesCard />} />
                            <Route path="/readouts/:id" element={<ReadoutsCard />} />
                            <Route path="/readouts/history/:id" element={<ReadoutsHistory />} />
                            <Route path="/invoice/:id" element={<Invoice />} />
                            <Route path="/settings/:id" element={<Settings />} />
                        </>
                    )
                }
                <Route path="/" element={<Login setToken={setToken} />} />
                <Route path="/error" element={<Error />} />
                <Route path="*" element={<Error />} />
            </Routes>
        </main>
    );
}

export default App;
