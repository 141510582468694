import { api } from "../app/api";
import { routes } from "../app/flat-api-routes";
import { IFlatInfo } from "./models/flats/IFlatInfo";

const flatsApi = api.injectEndpoints({
    endpoints: build =>({
        getFlat: build.query<IFlatInfo, any>({
            query: id =>({
                method: "GET",
                url: `${routes.getFlat}/${id}`
            })
        }),
        createFlat: build.mutation<void, IFlatInfo>({
            query: (request) =>({
                url: `${routes.createFlat}`,
                method: "POST",
                body: request
            })
        }),        
        updateFlat: build.mutation<void, IFlatInfo>({
            query: (request) =>({
                url: `${routes.updateFlat}/${request.id}`,
                method: "POST",
                body: request
            })
        }),
        deleteFlat: build.query<void, any>({
            query: id =>({
                method: "DELETE",
                url: `${routes.deleteFlat}/${id}`
            })
        })
    })
})

export const {
    useLazyGetFlatQuery,
    useCreateFlatMutation,
    useUpdateFlatMutation,
    useLazyDeleteFlatQuery
} = flatsApi;