import * as React from "react";

import { IconButton } from "common/components";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

export const LeftSideBar = () => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                width: "250px",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <IconButton
                    type="backward"
                    onClick={() => navigate(-1)}
                />
            </Box>
        </Box>
    );
};
