import * as React from "react";

import { Card } from "features/common/Card/Card";
import { Status } from "features/common/Status/Status";
import moment from "moment";
import {
    FormProvider,
    SubmitHandler,
    useController,
    useForm
} from "react-hook-form";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import {
    Button,
    Grid,
    IconButton,
    TextField,
    Typography
} from "@mui/material";

import {
    useCreateFlatMutation,
    useLazyDeleteFlatQuery,
    useLazyGetFlatQuery,
    useUpdateFlatMutation
} from "../../../../api/flatApi";
import { IFlatInfo } from "../../../../api/models/flats/IFlatInfo";
import { FlatActions } from "./FlatActions";
import { FlatValidationSchema } from "./FlatValidationSchema";

export const FlatCard = () => {
	const formMethods = useForm<IFlatInfo>({
		defaultValues: {
			id: "",
			name: "",
			price: 0,
			deposit: 0,
			readouts_day: 20,
			rent_date: moment(new Date()).format("YYYY-MM-DD"),
			comment: "",
		},
		resolver: yupResolver(FlatValidationSchema),
		mode: "onBlur",
	});

	const {
		formState: { errors },
		control,
		handleSubmit,
	} = formMethods;

	const { field: name } = useController({ name: "name", control });
	const { field: price } = useController({ name: "price", control });
	const { field: deposit } = useController({ name: "deposit", control });
	const { field: rent_date } = useController({ name: "rent_date", control });
	const { field: readouts_day } = useController({
		name: "readouts_day",
		control
	});
	const { field: comment } = useController({ name: "comment", control });

	const { id } = useParams();
	const [getFlatInfo] = useLazyGetFlatQuery();
	const [flatInfo, setFlatInfo] = React.useState<IFlatInfo | null>(null);
	const [updateFlat, updateStatus] = useUpdateFlatMutation();
	const [createFlat, createStatus] = useCreateFlatMutation();

	React.useEffect(() => {
		if (!!id && !flatInfo) {
			getFlatInfo(id)
				.unwrap()
				.then((flatInfo) => {
					if (!!flatInfo) {
						setFlatInfo(flatInfo);
						formMethods.reset(flatInfo);
					}
				});
		}
	}, [id, getFlatInfo, flatInfo, formMethods]);

	const handleSave: SubmitHandler<IFlatInfo> = React.useCallback(
		(data: IFlatInfo) => {
			if (!!data) {
				data.id = id ?? "";

				if (!!id) {
					updateFlat(data);
				} else {
					createFlat(data);
				}
			}
		},
		[id, updateFlat, createFlat]
	);

	React.useEffect(() => {
		if (!createStatus.isUninitialized && createStatus.isSuccess) {
			window.location.href = "/flat/" + createStatus.data;
		}
	}, [createStatus]);

	const [deleteFlat, deleteResult] = useLazyDeleteFlatQuery();
	const handleDeleteClick = React.useCallback(() => {
		deleteFlat(id);
	}, [id, deleteFlat]);

	React.useEffect(() => {
		if (deleteResult.isSuccess) {
			window.location.href = "/";
		}
	}, [deleteResult]);

	//TODO: нужно понять, нужен ли useCallback
	const handleSettingsClick = () =>  window.location.href = "/settings/" + id;

	return (
		<Card>
			<FormProvider {...formMethods}>
				<Grid container spacing={4} sx={{ alignItems: "baseline" }}>
					<Grid item xs={12} sm={9}>
						<Typography
							sx={{
								fontSize: 18,
								paddingBottom: 4,
							}}
							color="text.primary"
							gutterBottom
						>
							Информация по квартире
						</Typography>
					</Grid>
					<Grid item xs={12} sm={1} sx={{ textAlign: "end" }}>
						<IconButton
							onClick={handleSettingsClick}
						>
							<SettingsIcon />
						</IconButton>
					</Grid>
					<Grid item xs={12} sm={2} sx={{ textAlign: "end" }}>
						<IconButton
							color="error"
							onClick={handleDeleteClick}
						>
							<DeleteIcon />
						</IconButton>
					</Grid>
				</Grid>
				<Grid container spacing={4}>
					<Grid item xs={12} sm={12}>
						<TextField
							{...name}
							label="Название квартиры"
							fullWidth
							error={!!errors.name}
							required
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							{...price}
							type="number"
							label="Арендная плата"
							error={!!errors.price}
							fullWidth
							helperText={!!errors.price && "Целое число или 0"}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							{...deposit}
							type="number"
							label="Депозит"
							error={!!errors.deposit}
							fullWidth
							helperText={!!errors.deposit && "Целое число или 0"}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							{...rent_date}
							label="Дата сдачи"
							type={"date"}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							{...readouts_day}
							type="number"
							label="День расчета"
							error={!!errors.readouts_day}
							required
							fullWidth
							helperText={
								!!errors.readouts_day &&
								"Целое число от 1 до 28"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField
							{...comment}
							label="Комментарий"
							multiline={true}
							minRows={3}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Button
							variant="contained"
							type="submit"
							onClick={handleSubmit(handleSave)}
							fullWidth
						>
							Сохранить
						</Button>
					</Grid>
				</Grid>
				<Status
					initialState={
						updateStatus.isSuccess || updateStatus.isError
					}
					isError={updateStatus.isError}
				/>
			</FormProvider>
			{!!id && <FlatActions />}
		</Card>
	);
};
