import {
    createApi,
    fetchBaseQuery
} from "@reduxjs/toolkit/query/react";

export enum TagTypes {
	FlatAvatar = "FlatAvatar",
	ReadoutsNotificationsSettings = "ReadoutsNotificationsSettings",
	ReadoutsRemindersSettings = "ReadoutsRemindersSettings",
	ComissionSettings = "ComissionSettings",
}

export const api = createApi({
	reducerPath: "api",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_API_URL,
		prepareHeaders: (headers) => {
			const flarecaTokenData = localStorage.getItem("flareca_token");
			const userToken = JSON.parse(flarecaTokenData);
			if (!!userToken) {
				headers.set("Authorization", `Token ${userToken.token}`);
			}
		},
	}),
	tagTypes: [
		TagTypes.FlatAvatar,
		TagTypes.ReadoutsNotificationsSettings,
		TagTypes.ReadoutsRemindersSettings,
		TagTypes.ComissionSettings,
	],
	endpoints: () => ({}),
});
