import React from "react";

import { IconButton } from "common/components";
import { LinearIndeterminate } from "common/components/LinearIndeterminate";

import { Box } from "@mui/material";

import { useGetFlatsQuery } from "../../../api/flatsApi";
import { FlatListItem } from "./FlatsListItem";

export const FlatsList = () => {
    const { data: flats, isLoading } = useGetFlatsQuery();

    const handleClick = React.useCallback(() => {
        window.location.href = "/flat/new";
    }, []);

    return (
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            {isLoading && <LinearIndeterminate />}
            {flats && (
                <>
                    {flats?.map((flat, index) => (
                        <FlatListItem key={index} flat={flat} />
                    ))}
                    <Box sx={{ p: 5 }}>
                        <IconButton type="newFlat" onClick={handleClick} />
                    </Box>
                </>
            )}
        </Box>
    );
};
