import * as React from "react";

import { useFormContext } from "react-hook-form";

import { Grid } from "@mui/material";

import { NumericInput } from "../../common/elements";
import {
    IReadoutsCounter,
    IReadoutsCountersForm
} from "../models/IReadoutsCountersForm";

export interface IProps {
    index: number;
    counter: IReadoutsCounter;
}

export const Readout = ({ index, counter } : IProps) => {
    const { formState: { errors } } = useFormContext<IReadoutsCountersForm>();
    const counterName = counter.type_name + (!!counter.name ? ` (${counter.name})` : "" );
    
    return (
        <Grid
            container
            spacing={4}
            sx={{ pb: 3, mb: 5, borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
            <Grid item xs={12} sm={12}>
                <NumericInput
                    label={counterName}
                    fieldName={`formData.${index}.value`}
                    required={true}
                    regex={/[^0-9[.]/g}
                    length={6}
                    error={!!errors.formData?.[index]?.value}
                />
            </Grid>
        </Grid>
    );
} 