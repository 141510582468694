import * as React from "react";

import {
    Box,
    Divider,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";

import { FlatAvatar } from "./FlatAvatar";
import { ReadoutsNotification } from "./ReadoutsNotification";
import { ReadoutsReminder } from "./ReadoutsReminder/ReadoutsReminder";
import { Comission } from "./Сommission/Comission";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Stack>{children}</Stack>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

export const VerticalTabs = () => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Stack
			direction={"row"}
			alignItems={"center"}
			spacing={4}
			divider={<Divider orientation="vertical" flexItem />}
		>
			<Stack>
				<Typography variant="h5">Настройки</Typography>
			</Stack>
			<Stack
				direction={"row"}
				sx={{
					width: "800px",
					flexGrow: 1,
					bgcolor: "background.paper",
					display: "flex",
				}}
			>
				<Tabs
					orientation="vertical"
					variant="scrollable"
					value={value}
					onChange={handleChange}
					sx={{
						borderRight: 1,
						borderColor: "divider",
						width: "200px",
					}}
				>
					<Tab
						label="Аватар"
						{...a11yProps(0)}
						aria-selected={true}
					/>
					<Tab label="Уведомления" {...a11yProps(1)} />
					<Tab label="Напоминания" {...a11yProps(2)} />
					<Tab label="Комиссия" {...a11yProps(3)} />
				</Tabs>
				<TabPanel value={value} index={0}>
					<FlatAvatar />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<ReadoutsNotification />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<ReadoutsReminder />
				</TabPanel>
				<TabPanel value={value} index={3}>
					<Comission />
				</TabPanel>
			</Stack>
		</Stack>
	);
};
